import React, {useEffect} from 'react';
import {ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, makeVar} from "@apollo/client";
import {RetryLink} from "@apollo/client/link/retry";
import {onError} from "@apollo/client/link/error";
import Cookies from "js-cookie";
import AppAuthLoginFormModal from "../react/auth/app.auth.loginform.modal";
import {useState} from "react";
import { useReactiveVar } from '@apollo/client';
import {showAuth, authPhoneLogin} from "./react.vars";

let hdr = {};
const httpPrivateLink = new HttpLink({uri: '/front2api/graphql/private'});
const httpPublicLink = new HttpLink({uri: '/front2api/graphql/public'});


const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: Infinity,
        jitter: true
    },

    attempts: {
        max: 3,
        retryIf: (error, _operation) => {
            // не переспрашивать если получен код 401
            if(error.statusCode === 401){
                //console.log('UNAUTHORIZED 401.RETRY');
                return false;
            }

            //переспросить если получен код 419
            if(error.statusCode === 419){
                //console.log('UNAUTHORIZED 419.RETRY');
                return true;
            }
        }
    }


});

// собирает ошибки в массив
const addError = (errors, errorItem) => {

    if(!errors){
        errors = [];
    }

    errors.push(errorItem);

    return errors;

};


const errorLink = onError( ({graphQLErrors,networkError,response}) => {

    //console.error('ON ERRRORRR');
    let filtered_errors = null;
    //console.log('1-1');
    if (graphQLErrors){
        //console.log('1-2');
        for (let err of graphQLErrors){

            //console.log('1-2-1 gql',err);
            
            switch (err?.extensions?.code){
                // обработка кода 401 (Unauthorized)
                case '401':{
                    //console.log('1-2-2');
                    err.isAborted = true;
                    filtered_errors = addError(filtered_errors, err);
                    //console.log("HTTP 401");

                    if(err.extensions.phone){
                        //console.log('1-2-3');
                        authPhoneLogin(err.extensions.phone);
                    }
                    //console.log('1-2-4');
                    showAuth(true);
                    //console.log("HTTP 401-2",showAuth());
                    //console.log('1-2-5');
                }
                break;
                case '419':{
                    console.log('1-2-6');
                    err.isAborted = false;
                    filtered_errors = addError(filtered_errors, err);
                    //console.log("HTTP 419");
                    //console.log('1-2-7');
                }
                break;
                // все не обработанные ошибки передать дальше по цепочке
                default:{
                    //console.log('1-2-8');
                    err.isAborted = false;
                    filtered_errors = addError(filtered_errors, err);
                    //console.log('1-2-9');

                }
                //console.log('1-2-10');
            }
        }
        //console.log('1-2-11');
        filtered_errors.map((item) => {
            if(!item?.isAborted){
               console.error(`[GraphQL error]: message: ${item.message}, location: ${item.locations} path: ${item.path} extensions: ${item.extensions}`);
            }
        })
        //console.log('1-2-12');
    }

    if(networkError){
        //console.log('1-3');
        //console.log('networkError',networkError);
        let found = false;
        if(networkError.statusCode === 401){
            //console.log('1-3-1');
            found = true;
        }

        if(networkError.statusCode === 419){
            // некорректный csrf, повторить запрос
            found = true;
            //console.log('1-3-2');
        }
 
        //console.log('1-3-3');
 
        if(!found)
        {
            console.error(`[Network error]: ${networkError}`);
        }else{
            //response.errors = null;
            //networkError = null;
        }
    }

    // возвращаем отфильтрованные ошибки
    if(response){
        response.errors = filtered_errors;
    }

    //console.error('map response errors');
    // убрать из текста ошибки технические подробности
    if(response && response.errors){
        response.errors = response.errors.map( value => {
            //console.error(value);
            let arr = value.message.match('^\\[.+?\\]');

            // на продакшене можно тип ошибки отключить
            if(arr && arr[0]){
                value.message = value.message.replace(arr[0],'') + ' ' +  arr[0];
            }
            return value;

        })
    }
    //console.error('response errors done');

});


// определяет, будет ли запрос отправлен в публичный линк или в приватный
const checkPublic = (operation, context) => {
    let pbl = operation?.variables?.public;
    return (pbl === true)
}


// middleWare creation
// необходимо, что бы добавить csrftoken в заголовки запроса
const securityMiddleware = new ApolloLink((operation,forward) => {
    operation.setContext(({headers = {}}) => ({
        headers: {
            ... headers,
            csrf: Cookies.get('csrftoken') || null
        }
    }));

    return forward(operation);
})
    .split((op) =>  checkPublic(op,op.getContext()),  httpPublicLink, httpPrivateLink) // здесь идет выбор какой линк использовать в зависимости от значения, которое вернет функция checkPublic


const showAuthForm = () => {

}




//const dirLink = new HttpLink();

export const clientPrivate = new ApolloClient({
    //uri: '/front2api/graphql/private',
    link: from([retryLink, errorLink, securityMiddleware/*, dirLink*/]),
    // link: from([errorLink, httpPrivateLink]),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only'
        }
    },
    cache: new InMemoryCache(),
    credentials:'same-origin',
    headers: hdr
});


