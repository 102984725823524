import React, {useState, useRef, useEffect, Fragment} from 'react';

//import {Jumbotron,Form, Spinner, Container, FormFeedback, ModalBody, ModalFooter, ModalHeader, FormGroup, Button, Alert, Modal, Input, Label} from "reactstrap";
import  Form from 'react-bootstrap/Form';
import FormGroup from "react-bootstrap/FormGroup";
import Spinner from "react-bootstrap/Spinner";
import FormControl from "react-bootstrap/FormControl";
import Container from "react-bootstrap/Container";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from "react-bootstrap/ModalFooter";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';


import {loginUser, logout, useAuthState, useAuthDispatch, loginUserPhone, loginUserPhoneCode, registerUserPhone, registerUserPhoneCode} from './app.auth.main';
import {MaskedInput} from "../../components/mp.maskedinput";
import {DownTimer} from "../../components/mp.downtimer";
import {
    setInputValue,
    getInputValue,
    setInputFocus,
    getPhoneValue,
    buildError,
    buildErrorStr,
    parseUrlParams
} from "./app.auth.lib";
import {Fade, FormCheck} from "react-bootstrap";
//import {Link} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Toast from 'react-bootstrap/Toast';
import {ToastBody} from "react-bootstrap";
import {MpToast} from "../../components/mp.toast";
import MpToastComponent from "../../components/mp.toast.component";
import {LinkButton} from "../../components/reactstrap.linkbutton";
import {useReactiveVar} from "@apollo/client";
import {loginFallbackCallback} from "../../config/react.vars";



const useFocus = () => {
    const htmlElRef = useRef(null)

    const setFocus = () => {
        //alert(1);
        
        if (htmlElRef.current) {
            //alert(1.4);
            htmlElRef.current.focus();
            //alert(1.6)
        }
        //alert(2);}
    }

    return [ htmlElRef, setFocus ]
}




const AppAuthLoginFormModal = (props) => {

    let initStage = 'phone';
    const [loginRuntimeValue, setLoginRuntimeValue ] = useState(null);

    if(
        props.stage === 'reg' ||
        props.stage === 'phone' ||
        props.stage === 'pwd'
    ){
        initStage = props.stage;
    }

    let phoneStart = '7';

    // вернуть номер, который пользователь ввел после показа окна авторизации
    if(loginRuntimeValue){
        phoneStart = loginRuntimeValue;
    }

    let loginCallBack = useReactiveVar(loginFallbackCallback);

    if(props.phoneStart){


        let tempPhoneStart = props.phoneStart;

        if(tempPhoneStart[0] === '+'){
            tempPhoneStart = tempPhoneStart.substr(1);
        }
        let numberPattern = /\d+/g;
        tempPhoneStart = tempPhoneStart.match( numberPattern ).join('');

        if(tempPhoneStart.length > 11){
            tempPhoneStart = tempPhoneStart.substring(0,11);
        }

        if(tempPhoneStart.length === 11){
            phoneStart = tempPhoneStart;
        }

        if(tempPhoneStart.length < 11){

            if(tempPhoneStart[0] !== '7'){
                phoneStart += tempPhoneStart;
            }else{
                phoneStart = tempPhoneStart;
            }
        }
    }


    let inp_pwd_ref = useRef(null);
    let btn_submit_ref = useRef(null);

    let inp_login_ref = useRef(null);
    let inp_phone_ref = useRef(null);
    let inp_phonecode_ref = useRef(null);

    const [pwdActive, setPwdActive] = useState("");
    const [codeActive, setCodeActive] = useState("active");

    const [stage,setStage] = useState('phone');
    const [newStage, setNewStage] = useState("");

    const [loginValue, setLoginValue] = useState(phoneStart);

    const [lastSendPhone,setLastSendPhone] = useState("");
    const [doFade, setDoFade] = useState(false);

    const [loginValid,setLoginValid] = useState(false);
    const [loginInValid,setLoginInValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordInValid,setPasswordInValid] = useState(false);
    const [loginFeedbackMsg,setLoginFeedBackMsg] = useState('');
    const [passwordFeedbackMsg,setPasswordFeedBackMsg] = useState('');


    const [isLoginAlertOpen,setLoginAlertOpen] = useState(false);
    const [loginAlertMsg,setLoginAlertMsg] = useState("");

    const dispatch = useAuthDispatch();

    // так как результат - объект, а не массив, то имена полей должны быть такими же как и в app.auth.reducer
    const {loading,errorMessage,lastAction} = useAuthState();

    let loadingResend = (loading && lastAction?.type === 'REQUEST_LOGIN_PHONE');
    let loadingSendCode = (loading && (lastAction?.type === 'REQUEST_LOGIN_PHONECODE'));

    let initShowPasscode = false;

    if((newStage === '') && (stage !== initStage)){
        setStage(initStage);
    }


    if(phoneStart !== loginValue){
        setLoginValue(phoneStart);
    }

    //console.log('loginValue',phoneStart,loginValue,loginRuntimeValue);


    let msgPlain = '';
    let processed = false;
    // показать общую ошибку или ошибку, относящуюся ко входу через логин/пароль
    if(stage === 'pwd'){
        if(
            (lastAction?.type === 'CHECK_ERROR') ||
            (lastAction?.type === 'LOGIN_ERROR')
        ){

            msgPlain = buildErrorStr(errorMessage);
            processed = true;
        }
    }

    // если ошибка не была обработана выше
    if(!processed){
        // выводить ошибку только если это общая ошибка или
        // ошибка относящаяся ко входу через СМС
        if((stage === 'phone')||(stage === 'reg')){
            if(
                (lastAction?.type === 'CHECK_ERROR') ||
                (lastAction?.type === 'LOGIN_PHONECODE_ERROR') ||
                (lastAction?.type === 'LOGIN_PHONE_ERROR')
            ) {
                //console.log('ERROR_MESSAGE',errorMessage);


                msgPlain = buildErrorStr(errorMessage);

                let traceCode = errorMessage?.trace_code;
                if(Array.isArray(traceCode)){
                    let firstCode = traceCode[0];
                    if(firstCode === '210402A004'){
                        msgPlain += ' Зарегистрируйтесь ниже';
                    }
                }
            }

        }
    }

    // установить новое значение ошибки если оно изменилось
    if(msgPlain !== loginAlertMsg){
        setLoginAlertMsg(msgPlain);
        // показать ошибку если в переменной не пустое значение
        setLoginAlertOpen(msgPlain !== '');
    }


    if(lastAction){
        initShowPasscode = ((lastAction?.type === 'SUCCESS_LOGIN_PHONE')||(lastAction?.type === 'REQUEST_LOGIN_PHONECODE')||(lastAction?.type === 'LOGIN_PHONECODE_ERROR'));
    }


    const [phoneFeedbackMsg,setPhoneFeedBackMsg] = useState('');
    const [phoneCodeFeedbackMsg,setPhoneCodeFeedBackMsg] = useState('');
    const [phoneValid, setPhoneValid] = useState(false);
    const [phoneInValid,setPhoneInValid] = useState(false);
    const [phoneCodeValid, setPhoneCodeValid] = useState(false);
    const [phoneCodeInValid,setPhoneCodeInValid] = useState(false);

    const authCB = useReactiveVar(loginFallbackCallback);

    //const toggleLoginAlert = () => setLoginAlertOpen(!isLoginAlertOpen);

    // показываем снова скрывшиеся компоненты


    // при отображении/скрытии формы
    useEffect(()=>{
        clearFields(props.show);


    },[props.show])

    useEffect( () => {
        if(doFade){
            let tid = setTimeout(() => {
                setDoFade(false);
                setStage(newStage);
            },300);

        }

    }, [newStage] )

    // при первом запуске и при изменении stage
    useEffect(()=>{
        if(stage === 'pwd'){
            let val = getPhoneValue(inp_login_ref);
            if(val.length === 12){
                setInputFocus(inp_pwd_ref);
            }else{
                setInputFocus(inp_login_ref);
            }
        }
        if((stage === 'phone')||(stage === 'reg')){
            let val = getPhoneValue(inp_phone_ref);
            if(val.length === 12){
                if(showPassCode) {
                    setInputFocus(inp_phonecode_ref);
                }else{
                    setInputFocus(inp_phone_ref);
                }
            }else{
                setInputFocus(inp_phone_ref);
            }
        }

    },[stage])

    const [showPassCode,setShowPassCode] = useState(initShowPasscode);
    const [showTimer,setShowTimer] = useState(false);

    // установка каретки ввода номера телефона сразу после последней введенной цифры
    // при клике или селекте
    const setupPhoneCaret = (e) => {

        let val = e?.target?.value;

        if(val){

            let curPosStart = e.target.selectionStart;
            let curPosEnd = e.target.selectionEnd;

            // применять перемещение каретки если нет выделения в элементе
            if(curPosStart === curPosEnd){
                let caretPosition = val.length;

                // нужно найти позицию на которой встречается самая последняя цифра
                let idx = 1;
                for(let value of val){
                    if(value === '_'){
                        caretPosition = idx -1;
                        break;
                    }
                    idx++;
                }


                // проверка на крайние значения
                if(caretPosition < 1) caretPosition = 1;
                if(caretPosition > val.length) caretPosition = val.length;

                // не мешать установке курсора в заполненной части инпута
                if(caretPosition > curPosStart) caretPosition = curPosStart;

                // установка каретки в нужную позицию
                e.target.selectionStart = caretPosition;
                e.target.selectionEnd = caretPosition;
            }
        }

    }


    function loginInputClick(e){
        setLoginInValid(false);
        setupPhoneCaret(e);
    }

    function loginSelect(e){
        setupPhoneCaret(e);
     }

    function loginInputOnChange(e){

        //console.log('login onChange',e);
        setLoginInValid(false);
        setLoginAlertOpen(false);
        setLoginRuntimeValue(e.target.value);
    }

    function pwdInputClick(){
        setPasswordInValid(false);
    }

    function pwdInputOnChange(){

        setPasswordInValid(false);
    }

    function phoneInputOnClick(e){
        setPhoneInValid(false);
        setupPhoneCaret(e)

    }

    function phoneInputOnSelect(e){
        setupPhoneCaret(e);
    }

    function phoneInputOnChange(e){
        //console.log('phone onChange',e);
        setLoginRuntimeValue(e.target.value);
        setPhoneInValid(false);
        setLoginAlertOpen(false);
        setShowPassCode(false);
    }

    function phoneCodeInputOnClick(){
        setPhoneCodeInValid(false);

    }

    function phoneCodeInputOnChange(e){
        setPhoneCodeInValid(false);
        if(e?.target){
            setOtp(e.target.value);
        }
    }

    function clearFields( resetPassCode){
        // stage pwd
        setLoginValid(false);
        setLoginInValid(false);
        setLoginFeedBackMsg("");
        setPasswordValid(false);
        setPasswordInValid(false);
        setPasswordFeedBackMsg('');

        // stage phone
        setPhoneValid(false);
        setPhoneInValid(false);
        setPhoneFeedBackMsg("");
        setPhoneCodeValid(false);
        setPhoneCodeInValid(false);
        setPhoneCodeFeedBackMsg("");

        setLoginAlertMsg('');
        setLoginAlertOpen(false);


        if(resetPassCode)setShowPassCode(false);
        //console.log("app.auth.loginform.modal: clear Form");

    }

    function checkFields(isResend){

        let ret = false;
        let processed = false;

        // проверка полей относящихся ко входу по паролю
        if(stage === 'pwd'){
            let login_val = getPhoneValue(inp_login_ref).trim();
            if((login_val === '+' ) || (login_val === '')){
                setInputFocus(inp_login_ref);
                setLoginValid(false);
                setLoginInValid(true);
                setLoginFeedBackMsg("Заполните это поле");
                processed = true;
            }


            if((!processed)&&(login_val.length < 12)){
                setInputFocus(inp_login_ref);
                setLoginValid(false);
                setLoginInValid(true);
                setLoginFeedBackMsg("Номер телефона должен состоять из 11 цифр");
                processed = true;
            }


            if(!processed){

                let pwdValue = ''+getInputValue(inp_pwd_ref);
                if(pwdValue.trim() === ''){
                    let fromLogin = false;

                    if(document.activeElement === inp_login_ref.current){
                        fromLogin = true;
                    }

                    setInputFocus(inp_pwd_ref);

                    // если фокус до этого был не в поле логина, то напишем ошибку
                    // если фокус был в поле логина то фокус просто перемещается в поле пароля, без ругательств что бы не пугать пользователя
                    if(!fromLogin){
                        setPasswordValid(false);
                        setPasswordInValid(true);
                        setPasswordFeedBackMsg('Заполните это поле')
                    }

                    processed = true;
                }
            }
        }

        // проверка полей относящихся к телефону
        if((stage === 'phone')||(stage === 'reg')){

            let phone_val = getPhoneValue(inp_phone_ref);
            if(lastSendPhone !== '') phone_val = lastSendPhone;
            let phonecode_val = getInputValue(inp_phonecode_ref);
            if((phone_val === '')||(phone_val === '+')){
                setPhoneInValid(true);
                setPhoneValid(false);
                setPhoneFeedBackMsg('Заполните это поле');
                setInputFocus(inp_phone_ref);
                processed = true;
            }

            if((!processed)&&(phone_val.length < 12)){
                setPhoneInValid(true);
                setPhoneValid(false);
                setPhoneFeedBackMsg('Номер телефона должен состоять из 11 цифр');
                setInputFocus(inp_phone_ref);
                processed = true;
            }

            // если требуется ввод СМС-кода проверяем поле кода на заполненность
            // дополнительно проверяем что это не нажата кнопка запроса переотправки кода

            if(showPassCode && !isResend){

                if(phonecode_val === ''){
                    setPhoneCodeValid(false);
                    setPhoneCodeInValid(true);
                    setPhoneCodeFeedBackMsg('Заполните это поле');
                    // если фокус не устанавливался до этого - ставим фокус в поле СМС-кода
                    if(!processed){
                        setInputFocus(inp_phonecode_ref);
                    }
                    processed = true;
                }
            }
        }

        ret = !processed;

        return ret;

    }


    async function loginSubmitPhone(e, isResend, url){


        let response = null;
        let phNum = getPhoneValue(inp_phone_ref);

        if(!showPassCode || isResend){

            if((phNum === '')&&(isResend)) phNum = lastSendPhone;

            // отправка номера телефона для кода,
            console.log('отправка номера телефона без кода');
            if(stage === 'phone'){
                response = await loginUserPhone(dispatch,{phone:phNum});
                //console.log("****** loginUserPhone *******");
            }

            if(stage === 'reg'){
                response = await registerUserPhone(dispatch,{phone:phNum});
                //console.log("****** registerUserPhone *******");
            }

            //console.log(response);
            //console.log('*****************************');

                // после отправки включаем таймер без разбора какой ответ получим
                if(isResend){
                    setShowTimer(true);
                }

                // если все хорошо - показываем окно ввода пароля
                if(response && response.data){
                    setLastSendPhone(phNum);
                    setShowPassCode(true);
                    setShowTimer(true);
                    setInputFocus(inp_phonecode_ref);

                    return response.data;
                }

                // если есть ошибки - показываем их в алерте ниже

            }else{

            // либо отправка кода для авторизации, либо запрос на повторную отправку
            //console.log('отправка номера телефона с кодом');

            // вспоминаем телефон на который был отправлен код
            phNum = lastSendPhone;
            let pwc = getPhoneValue(inp_phonecode_ref);

            // отправка кода и номера телефона
            if(stage === 'phone'){
                response = await loginUserPhoneCode(dispatch,{phone:phNum, passcode:pwc})
                //console.log("****** loginUserPhoneCode *******");
            }

            if(stage === 'reg'){
                response = await registerUserPhoneCode(dispatch,{phone:phNum, passcode:pwc})
                //console.log("****** registerUserPhoneCode *******");
            }
            // обработка результата
            //console.log(response);
            //console.log('*****************************');

                if(response && response.data){
                    // если все хорошо ставим зеленые галочки в полях
                    setPhoneValid(true);
                    setPhoneCodeValid(true);

                    setTimeout(() => {

                        let callbackCopy = null;

                        // перед тем как закрыть модальное окно авторизации, сохранить колбэк успешного завершения
                        if(authCB?.callback){
                            callbackCopy = authCB.callback.valueOf();
                        }

                        if(props.toggle) props.toggle();

                        // если есть валидный колбэк - вызываем его
                        if(callbackCopy && (typeof callbackCopy === 'function')){
                            callbackCopy();
                        }else{
                            // если нет колбэка разбираемся с перезагрузкой страницы
                            url ? location.assign(url) : location.reload();
                        }

                    }, 100);

                    return response.data;
                }

                // если есть ошибки
                setInputValue(inp_phonecode_ref,'');
                setInputFocus(inp_phonecode_ref);

            }

            /*
            console.log("*2***********");
            console.log(response);
            console.log('*2************');
            */

            //console.log('test error: '+  response.error.code);



            let errorMsg = buildError(response);

            let traceCode = response?.trace_code;
            if(Array.isArray(traceCode)){
                let firstCode = traceCode[0];
                if(firstCode === '210402A004'){
                    errorMsg += ' Зарегистрируйтесь ниже';
                }
            }

            console.log('error msg: ' + errorMsg);

            setLoginAlertMsg(errorMsg + ' 12e');
            setLoginAlertOpen(true);
    }


    // авторизация, отправка запроса
    async function loginSubmitPwd(e, url){

        try{

            let login_val = getPhoneValue(inp_login_ref);
            let password_val = getInputValue(inp_pwd_ref);



            let response = await loginUser(dispatch,{login_val, password_val});
            console.log("AFTER_LOGIN");
            console.log(response);

            if(response.data){
                // выполняется в случае успешного ответа
                setLoginValid(true);
                setPasswordValid(true);
                setLoginFeedBackMsg('Пользователь успешно проверен');

                //location.assign('/');

                /*
                setTimeout(() => {
                    if(props.toggle) props.toggle();
                    url ? location.assign(url) : location.reload();
                }, 100);
*/
                setTimeout(() => {

                    let callbackCopy = null;

                    // перед тем как закрыть модальное окно авторизации, сохранить колбэк успешного завершения
                    if(authCB?.callback){
                        callbackCopy = authCB.callback.valueOf();
                    }

                    if(props.toggle) props.toggle();

                    // если есть валидный колбэк - вызываем его
                    if(callbackCopy && (typeof callbackCopy === 'function')){
                        callbackCopy();
                    }else{
                        // если нет колбэка разбираемся с перезагрузкой страницы
                        url ? location.assign(url) : location.reload();
                    }

                }, 100);



                return response.data;
            }

            // выполняется в случае ошибки

            if(!response.error){
                response.error = {code:-2,msg:"Пришел пустой ответ",descr:''};
            }

            // сброс пароля
            setInputValue(inp_pwd_ref,'');

            let errorMsg = buildError(response);
            setLoginAlertMsg(errorMsg);
            setLoginAlertOpen(true);
            setInputFocus(inp_login_ref);

        } catch(e){
            console.log("CATCH 2 ERROR");
            console.log(e);
            setLoginAlertMsg(e.message);
            setLoginAlertOpen(true);
            setInputFocus(inp_login_ref);
        }
    }

    async function loginSubmit(e){

        e.preventDefault();

        const {url} = props;

        setLoginAlertOpen(false);

        let isResend = true;
        //console.log(e);
        if(e.nativeEvent && e.nativeEvent.submitter){
            if(e.nativeEvent.submitter.id !== 'resendcodeButton'){
                isResend = false;
            }
        }


        if(checkFields(isResend)){
            if(stage === 'pwd'){
                await loginSubmitPwd(e, url);
            }

            if((stage === 'phone')||(stage === 'reg')){
                await loginSubmitPhone(e,isResend,url);
            }
        }
    }

    function fromPwdClick(){
        setNewStage('phone')
        let val = getPhoneValue(inp_login_ref);
        setLoginAlertOpen(false);
        setLoginValue(val);
        setCodeActive("active");
        setPwdActive("");
        if(stage !== 'phone'){
            setDoFade(true);
        }

    }

    function fromPhoneClick(){
        setNewStage('pwd')
        let val = getPhoneValue(inp_phone_ref);
        if(lastSendPhone !== "") val = lastSendPhone;
        setLoginAlertOpen(false);
        setLoginValue(val);
        setPwdActive("active");
        setCodeActive("");
        if(stage !== 'pwd'){
            setDoFade(true);
        }
    }

    function fromRegClick(){

        let val = getPhoneValue(inp_phone_ref);
        if(lastSendPhone !== "") val = lastSendPhone;
        if(stage === 'pwd'){
            val = getPhoneValue(inp_login_ref);
        }
        setLoginAlertOpen(false);
        setLoginValue(val);
        setNewStage('reg');
        setInputFocus(inp_phone_ref);
        setPwdActive("");
        setCodeActive("");
        if(stage !== 'reg'){
            setDoFade(true);
        }
    }

    function timerDone(){
        setShowTimer(false);
    }

    const closeLogin = () => {

        if(props.toggle){
            props.toggle();
            return true;
        }else {
            return false;
        }

    }

    const loginHide = () => {
        if(props.toggle){
            props.toggle();
            return true;
        }else {
            return false;
        }
    }

    const loginShow = () => {
        if(stage === 'pwd'){
            setInputFocus(inp_login_ref);
         }

        if((stage === 'phone')||(stage === 'reg')){
            setInputFocus(inp_phone_ref);
        }
    }

    const [otp, setOtp] = useState('');

    useEffect ( () => {
        if (((stage === 'phone')||(stage === 'reg')) && showPassCode){

            if (!window.OTPCredential){
                //console.log('WebOTP Api is not supported');
            }
            else{
                //console.log('WebOTP Api is supported');
                let ac = new AbortController();
                const timeoutID = setTimeout( () => {
                    ac.abort();
                }, 10 * 60 * 1000);

                navigator.credentials
                    .get({
                        otp: { transport: ["sms"]},
                        signal: ac.signal
                    })
                    .then( otp => {
                        setOtp(otp.code);
                        //console.log('otp', otp);

                    })
                    .catch( err => {
                        //console.log('otp error: ',err);
                    });


                return () =>{
                    clearTimeout(timeoutID);
                }
            }
        }
    })

    return(

        <Fragment>
            <Modal show={props.show}  autoFocus={false} id="modalLogin" backdrop={true} keyboard className={props.className} onHide={loginHide} onShow={loginShow}>
                <Form onSubmit={loginSubmit} >
                    <ModalBody className="p-6 p-md-10">
                        <Button type="button" variant="link" className="btn-close position-absolute z-1 top-0 end-0 m-4 text-decoration-none " onClick={closeLogin}>&nbsp;</Button>

                        {
                            ((stage === 'pwd') || (stage === 'phone')) &&
                            (
                                <Fragment>
                                    <h4 className="fs-2 mb-6">Вход в личный кабинет</h4>

                                    <Nav bsPrefix="nav nav-tabs-alt"  className="mb-8" >
                                        <Button bsPrefix="nav-item nav-link" disabled={loading}  className={pwdActive} onClick={fromPhoneClick} >По паролю</Button>
                                        <Button bsPrefix="nav-item nav-link" disabled={loading}  className={codeActive} onClick={fromPwdClick} >По СМС-коду</Button>
                                    </Nav>
                                </Fragment>
                            )
                        }

                        {
                            (stage === 'reg') &&
                            (<h4 className="fs-2 mb-6">Регистрация</h4>)
                        }

                        <Fade in={!doFade} timeout={50} >

                        <div style={{minHeight:"272px"}}>
                        {stage === 'pwd' && (
                            <div>

                                <FormGroup className="mb-4">
                                    <Form.Label className="fz-6" htmlFor="loginUserName">Номер телефона</Form.Label>
                                    <MaskedInput mask="\+9 (999) 999 99-99" maskPlaceholder="_"
                                                 innerRef={inp_login_ref}

                                                 ppattern = "[0-9,\(,\),\+,\-]*"
                                                 type="tel"
                                                 inputMode="tel"

                                                 name="username" id="loginUserName"
                                                 disabled={loading}
                                                 isInvalid = {loginInValid} isValid={loginValid}
                                                 onClick={(e) => (loginInputClick(e))} onChange={ loginInputOnChange}
                                                 onSelect={(e) => (loginSelect(e))}
                                                 value={loginValue} size="lg"/>
                                    <FormControl.Feedback type="invalid" >{loginFeedbackMsg}</FormControl.Feedback>
                                </FormGroup>
                                <FormGroup  className="mb-4">
                                    <Form.Label htmlFor="loginPassword">Пароль</Form.Label>
                                    <Form.Control ref={inp_pwd_ref} type="password" name="password" id="loginPassword"
                                            disabled={loading}
                                           onClick={pwdInputClick} onChange={pwdInputOnChange}
                                           isInvalid={passwordInValid} isValid={passwordValid}  size="lg"/>
                                    <FormControl.Feedback type="invalid">{passwordFeedbackMsg}</FormControl.Feedback>
                                    <Form.Control type="hidden"/>
                                </FormGroup>
                            </div>
                        )}
                        {((stage === 'phone')||(stage === 'reg')) && (
                            <div>

                                { !showPassCode &&
                                    (<FormGroup  className="mb-4">
                                        <Form.Label  className="fz-6" htmlFor="loginUserPhone">Номер телефона</Form.Label>
                                        <MaskedInput autoFocus innerRef={inp_phone_ref}
                                                     mask="\+9 (999) 999 99-99" maskPlaceholder="_"

                                                     ppattern = "[0-9,\(,\),\+,\-]*"
                                                     type="tel"
                                                     inputMode="tel"

                                                     name="userphone" id="loginUserPhone"
                                                     disabled={loading}  value={loginValue}
                                                     isInvalid = {phoneInValid} isValid={phoneValid}
                                                     onChange={phoneInputOnChange}
                                                     onClick={(e) => (phoneInputOnClick(e))}
                                                     onSelect={ (e) => (phoneInputOnSelect(e))}
                                                     size="lg"/>
                                        <FormControl.Feedback type="invalid">{phoneFeedbackMsg}</FormControl.Feedback>
                                    </FormGroup>)
                                }

                                {  showPassCode &&
                                    (<FormGroup  className="mb-4">
                                        <p className="mb-1 fs-3 fw-bold">{lastSendPhone}</p>
                                        <p>На ваш номер отправлено СМС с кодом для входа.
                                            <br/>
                                            {showTimer && showPassCode &&  (
                                                <Fragment>
                                                    <span>Выслать повторно через&nbsp;</span>
                                                    <DownTimer startValue = "60" onDone = {timerDone}/>
                                                    <span>&nbsp;с.</span>
                                                </Fragment>
                                                    )}

                                            {!showTimer && showPassCode && (
                                                <Button className="p-0" to="#" id="resendcodeButton" name = "resendcodeButton"
                                                      disabled={loading}  onClick={loginSubmit} variant="link">
                                                    Выслать повторно
                                                    {loadingResend && <Spinner animation="border" className="ms-2" size="sm" variant="secondary" />}
                                                </Button>
                                            )}
                                        </p>

                                        <FormControl autoFocus ref={inp_phonecode_ref}
                                                     placeholder="СМС-код"

                                                     pattern = "[0-9]*"
                                                     type="text"
                                                     inputMode="numeric"
                                                     autoComplete="one-time-code"

                                                     name="userphone" id="loginUserPhone"

                                                     disabled={loading || !showPassCode}
                                                     isInvalid = {phoneCodeInValid} isValid={phoneCodeValid}
                                                     onChange = {(e) => (phoneCodeInputOnChange(e))}
                                                     onClick={phoneCodeInputOnClick}
                                                     size="lg"
                                                     value = {otp}

                                        />
                                        <FormControl.Feedback type="invalid" >{phoneCodeFeedbackMsg}</FormControl.Feedback>
                                    </FormGroup>)
                                }
                            </div>
                        )}

                        {
                            ((stage === 'pwd') || ((stage ==='phone')&&(showPassCode))) &&
                            (<div className="my-6 d-none">
                                <FormCheck id="remember-me" label="Запомнить меня"/>
                            </div>)
                        }

                        { ((stage === 'phone')||(stage==='reg')) && !showPassCode && (

                            <Button variant="dark" size="lg" type="submit" id="phoneButton" disabled={loading} className="w-100 carbonus-black-button">
                                <span>Получить SMS-код</span>
                                {loading && <Spinner animation="border" className="ms-2" size="sm" variant="light" />}
                            </Button>
                        )}

                        { ((stage === 'phone')||(stage==='reg')) && showPassCode && (
                            <Button variant="dark" size="lg" type="submit" id="phonecodeButton" disabled={loading} className="w-100 carbonus-black-button">
                                <span>Войти</span>
                                {loadingSendCode && <Spinner animation="border" className="ms-2" size="sm" variant="light" />}
                            </Button>
                        )}

                        {stage === 'pwd' &&(

                                <Button ref={btn_submit_ref} variant="dark" size="lg" type="submit"
                                        id="loginButton" disabled={loading} className="w-100 carbonus-black-button">
                                    <span>Войти</span>
                                    {loading && <Spinner animation="border" className="ms-2" size="sm" variant="light" />}
                                </Button>
                        )}
                        </div>
                        </Fade>

                        <MpToastComponent
                            type="danger"
                            show={isLoginAlertOpen}
                            message={loginAlertMsg}
                            className="mt-4 w-100 border-0"
                        />

                        { stage !== 'reg' &&
                            (<div className="fs-4 text-center mt-6">
                                <Button disabled={loading} onClick={fromRegClick} className="text-dark" variant="link">Зарегистрироваться</Button>
                            </div>)}

                        {stage === 'reg' && (
                            <div className="fs-4 text-center mt-6">
                                    <Button disabled={loading} onClick={fromPwdClick} className="text-dark" variant="link">Войти как зарегистрированный пользователь</Button>
                            </div>
                        )}

                    </ModalBody>
                </Form>
            </Modal>
        </Fragment>
    );
}

export default AppAuthLoginFormModal;
